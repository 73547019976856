const actions = {
    LOAD: "LOAD",
    SET_DATA: "SET_DATA",
    UNAUTHENTICATED: "UNAUTHENTICATED",
    AUTHENTICATED: "AUTHENTICATED",
    ERROR: "ERROR",
    ADD_DESTINATION: "ADD_DESTINATION",
    AUTHENTICATION_ERROR: "AUTHENTICATION_ERROR",
};

export default actions